import React, {useState, useEffect, Fragment} from 'react';
import SEO from "../components/seo";
import Layout from "../components/Layout/Layout";
import {PrivacyPolicy} from "../components/PrivacyPolicy/PrivacyPolicy";

export default function privacy({}) {

    return (
        <Fragment>
            <SEO title="Politicas de Privacidad" description="Politicas de privacidad"/>
            <Layout>
                <PrivacyPolicy/>
            </Layout>
        </Fragment>
    )
}