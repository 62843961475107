import React, {useState, useEffect} from 'react';
import styles from './privacypolicy.module.scss'
import {useRecoilState} from "recoil";
import {Mode} from "../../recoil/atoms";

export function PrivacyPolicy({}) {

        const [mode, setMode] = useRecoilState(Mode);

        useEffect(()=>{
                console.log(mode)
        },[mode])


        return (
            <div style={{color: mode === 'light' ? '#000': '#fff'}} className={styles.container}>
                    POLÍTICA DE PRIVACIDAD Privacidad del Visitante <br/>
                    Estamos comprometidos a proteger la privacidad de los Visitantes a este sitio [www.itesa.ar] (el
                    “Sitio”). En consecuencia, nuestro Sitio no recolecta información personal de los usuarios, a
                    menos que los mismos nos proveyeran la misma a nosotros, en forma voluntaria y consciente.
                    Cuando Ud. visita nuestro Sitio, su navegador automáticamente nos envía una dirección de IP
                    (Protocolo de Internet) y ciertas otras informaciones (incluyendo el tipo de navegador que Ud.
                    está usando). Podemos usar esta información de la dirección de IP y otras informaciones para
                    proveer a Ud. acceso al Sitio y de otro modo administrar el Sitio. En ciertas partes del Sitio,
                    podremos solicitar a Ud. que nos provea su nombre y apellido, su domicilio, ciudad, provincia,
                    código postal u otras informaciones, de modo que podamos proveer a Ud. la información que
                    nos solicite y/o de modo que Ud. pueda participar de alguna promoción de algún evento o
                    búsquedas laborales que Itesa Innovation Company SAS. organice. En caso de que Ud. nos
                    deje sus datos, permite que Itesa Innovation Company SAS. se comunique con Ud. para y/o
                    organizar, diseñar, elaborar, segmentar y/o dirigir promociones publicitarias, acciones de
                    marketing, venta directa y/o enviarle comunicaciones relativas a este Sitio o alguno de los
                    productos que comercializa y elabora Itesa Innovation Company SAS. y/u otras actividades
                    análogas y/o para cualquier otro fin que fuera estrictamente necesario a efectos de que Itesa
                    Innovation Company SAS pueda brindarle sus servicios. Itesa Innovation Company SAS
                    informa que los servicios mencionados anteriormente podrán ser prestados en forma directa
                    por Itesa Innovation Company SAS y/o a través de terceros contratados a tales efectos. Ud. no
                    está obligado a proveernos tal información, pero sin la misma no podremos proveerle la
                    información que solicite y/o no podrá participar en las promociones antes mencionadas. El Sitio
                    también puede incluir ciertas características que permitan a Ud. comunicarse con nosotros a
                    través del correo electrónico o de otro modo. Si una característica de correo electrónico fuera
                    provista y Ud. decidiera utilizarla, Ud. deberá proveernos su dirección de correo electrónico. Por
                    lo tanto Ud. es responsable si decide usar estas características de comunicación para
                    proveernos información personal identificatoria (tales como al incluir su nombre en el cuerpo de
                    un mensaje de correo electrónico). Cuando Ud. nos provee cualquier información personal, Ud.
                    nos comunica que nos está proveyendo dicha información voluntariamente y acepta que otorga
                    su consentimiento libre, expreso e informado para que la información sea utilizada con las
                    finalidades arriba mencionadas, y autoriza a que la misma sea tratada, almacenada, recopilada
                    o cedida y/o transferida por Itesa Innovation Company SAS. a sus afiliadas, controladas,
                    vinculadas, relacionadas y/o a los contratistas que nos preste servicios (pero en este último
                    caso la información es divulgada solo apenas con la finalidad de prestar tales servicios), aun
                    cuando cualquiera de ellos se encuentren en países que no tengan los mismos niveles de
                    privacidad que la Argentina y cuando esto fuera estrictamente necesario a efectos de cumplir
                    con alguna de las finalidades arriba mencionadas. No usaremos tal información para fines
                    comerciales, a menos que esté específicamente declarado en el contexto, pero podemos
                    responder los mensajes de correo electrónico que Ud. nos envíe y usar tales informaciones
                    para las finalidades arriba mencionadas. Ud. puede ejercer el derecho de acceso, rectificación
                    y supresión de los datos personales que Ud. voluntariamente nos ha proporcionado
                    comunicándose con nuestro centro de atención al cliente al mail hello@itesa.co.
                    Intentamos asegurar que la información personal identificatoria que Ud. nos provea
                    permanezca privada. No venderemos, daremos en locación, comercializaremos ni de otro
                    modo divulgaremos la información personal identificatoria que Ud. provea a cualquier tercero
                    fuera de nuestras afiliadas, controladas, relacionadas o vinculadas, los contratistas que nos
                    presten servicios (pero en este último caso la información es divulgada solo apenas con la
                    finalidad de prestar tales servicios), a menos que seamos obligados por ley o creyéramos de
                    buena fe que Ud. está infringiendo estas políticas o leyes nacionales, provinciales o
                    municipales. Tenga en cuenta, sin embargo, que puede ser posible que terceros lean los
                    mensajes que Ud. envíe vía Internet, por lo tanto no envíe por correo electrónico cualquier
                    información que Ud. quisiera mantener confidencial. Como muchas compañías, nosotros
                    utilizamos la tecnología de “cookies” en nuestro Sitio. Las cookies son porciones de información
                    que un sitio transfiere al disco rígido de un individuo con el objeto de llevar un registro. Mejoran
                    la experiencia online al guardar sus preferencias mientras usted está visitando un sitio en
                    particular. Las cookies no contienen información personal identificable y no pueden hacer una
                    reseña de su sistema o recopilar información de su disco rígido. Mientras usted visualiza
                    nuestro Sitio nosotros podemos colocar una “cookie” en su computadora. Las cookies
                    temporales se utilizan con fines como el de contar el número de visitas a nuestro sitio. Estas
                    cookies temporales son eliminadas cuando usted sale de su navegador. Una cookie
                    permanente también puede ser almacenada por su navegador en su computadora. Cuando
                    usted ingresa, este tipo de cookies nos cuenta si usted ha visitado nuestro sitio anteriormente o
                    si usted es un nuevo visitante. La cookie no obtiene información personal sobre usted ni nos
                    suministra ninguna manera de contactarlo, y la cookie no extrae ninguna información de su
                    computadora. La porción de “ayuda” de la barra de herramientas en la mayoría de los
                    navegadores le indicará cómo evitar que su navegador acepte nuevas cookies, cómo hacer
                    para que el navegador le informe cuando usted reciba una cookie nueva, o cómo bloquear las
                    cookies todas juntas. Cuando utiliza este Sitio, Ud. presta su consentimiento a la transmisión de
                    su información personal identificatoria y a su uso conforme se describe en el presente. Tenga
                    en cuenta que esta política de privacidad resulta aplicable solamente a este Sitio, y no a otros
                    sitios, incluyendo los sitios separados de Itesa Innovation Company SAS o los sitios separados
                    de cualquiera de sus marcas, las que pueden tener políticas de privacidad diferentes a las
                    presentes.<br/><br/>
                    Protección de Datos Personales<br/>
                    El titular de los datos personales tiene la facultad de ejercer el derecho de acceso a los mismos
                    en forma gratuita a intervalos no inferiores a seis meses, salvo que se acredite un interés
                    legítimo a efecto conforme lo establecido en el Artículo 14, Inciso 3 de la Ley No 25.326. La
                    Agencia de Acceso a la Información Pública, en su carácter de Órgano de Control de la Ley
                    25.326, tiene la atribución de atender las denuncias y reclamos que interpongan quienes
                    resulten afectados en sus derechos por incumplimiento de las normas vigentes en materia de
                    protección de datos personales.<br/><br/>
                    Otra Información que Ud. Envíe<br/>
                    Si Ud. usa las características de comunicación de este Sitio para proveernos otra información
                    más allá de su información personal y datos identificatorios, incluyendo sugerencias acerca del
                    sitio, ideas sobre productos y publicidad, y cualquier otra información relacionada, tales
                    informaciones pasan a pertenecer en forma gratuita a nosotros y pueden ser usadas,
                    reproducidas, modificadas, distribuidas y divulgadas por nosotros de cualquier forma que
                    escojamos.
            </div>
        )
}